import { chat, customer_service } from "@dnb/eufemia/icons";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../apiConfig";
// import HistoryButton from "../components/Buttons/HistoryButton";
import SaveButton from "../components/Buttons/SaveButton";
import ChatBotCard from "../components/Cards/ChatModeCard";
import {
  setAgentCheck,
  setBotCheck,
  setModes,
} from "../features/chatModeSlice";

export default function ChatMode() {
  const dispatch = useDispatch();
  const modes = useSelector((state) => state.chatmode.modes);
  const params = useParams();
  const { t } = useTranslation();

  const changes = useSelector((state) => state.change.changes);


  useEffect(() => document.title = `${params.bot?.toUpperCase()}: Chat Mode`, [])


  useEffect(() => {
    api
      .get(`${params.bot}/chat-mode`)
      .then((res) => {
        dispatch(setModes(res.data.body));
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      dispatch(setModes({}));
    };
  }, []);
  return (
    <ChatModeWrapper>
      <CardWrapper>
        {modes && modes["agent"] && (
          <ChatBotCard
            icon={customer_service}
            mode={t("chatModePage.agent")}
            description={t("chatModePage.description")}
            checked={modes.agent}
            onChange={(value) => {
              dispatch(setAgentCheck(value));
            }}
          />
        )}
        {modes && modes["bot"] && (
          <ChatBotCard
            icon={chat}
            mode={t("chatModePage.bot")}
            description={t("chatModePage.description")}
            checked={modes.bot}
            onChange={(value) => {
              dispatch(setBotCheck(value));
            }}
          />
        )}
      </CardWrapper>
      <ButtonWrapper>
        <SaveButton disabled={!changes}/>
        {/* <HistoryButton /> */}
      </ButtonWrapper>
    </ChatModeWrapper>
  );
}

const ChatModeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardWrapper = styled.div`
  display: flex;
  gap: 6rem;

  @media screen and (max-height: 700px) {
    gap: 4rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-top: 5rem;

  @media screen and (max-height: 700px) {
    margin-top: 1rem;
  }
`;
