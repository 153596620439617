import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export default function BotCard({ name, icon }) {
  return (
    <BotCardWrapper to={`/bots/${name.toLowerCase()}`}>
      <img src={icon} alt={name} />
      <Name>{name}</Name>
    </BotCardWrapper>
  );
}

const BotCardWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #333333;
  border-radius: 0.5rem;

  border: solid 1px ${(props) => props.theme.borderColor};

  padding: 1.8rem;

  img {
    width: 6rem;
  }

  @media screen and (max-height: 700px) {
    width: 10rem;
    height: 10rem;

    img {
      width: 4.5rem;
    }
  }

  width: 12rem;
  height: 12rem;
  text-decoration: none;

  color: ${(props) => props.theme.cardTextColor};

  &:hover {
    background-color: ${(props) => props.theme.hoverColor};
    color: ${(props) => props.theme.hoverTextColor};
    border: 1px solid ${(props) => props.theme.hoverBorderColor};
  }
`;

const Name = styled.div`
  font-size: var(--font-size-large);
  font-weight: var(--regular);
  padding-top: 16px;
`;
