import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../apiConfig";
// import HistoryButton from "../components/Buttons/HistoryButton";
import SaveButton from "../components/Buttons/SaveButton";
import Loader from "../components/Loaders/Loader";
import FilterParameter from "../components/Parameters/FilterParameter";
import { setFilters } from "../features/filtersSlice";
import PlusButton from "../components/Buttons/PlusButton";
import FilterDialog from "../components/Dialogs/FilterDialog";
import { Anchor, Tooltip } from "@dnb/eufemia";
export default function Filters() {
  const dispatch = useDispatch();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const filters = useSelector((state) => state.filters.filters);
  const lang = useSelector((state) => state.lang.queriesLang);
  const changes = useSelector((state) => state.change.changes);

  useEffect(
    () => (document.title = `${params.bot?.toUpperCase()}: Filters`),
    []
  );
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    let isMounted = true; // fix: Can't perform a React state update on an unmounted component
    setIsLoading(true);

    // Fetching the filters
    api
      .get(`${params.bot}/filters`, {
        params: {
          lang: lang,
        },
      })
      .then((res) => {
        // Adding Ids to each filters so it can be a draggable element
        const withId = res.data.body.parameters.map((param, index) => ({
          ...param,
          id: index + 1,
          child_parameters: param.child_parameters?.map(
            (childParam, childIndex) => ({
              ...childParam,
              id: childIndex + 1,
            })
          ),
        }));

        if (isMounted) {
          dispatch(setFilters(withId));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error("Unable to load filters");
        console.error(err);
        setIsLoading(false);
      });

    return () => {
      dispatch(setFilters([]));
    };
  }, [dispatch, params.bot, lang]);

  function handleAdd() {
    setDeleteDialogOpen(true);
  }
  const noOfItem = filters.parameters.length % 2 == 0 ? true : false;
  return !isLoading ? (
    <Wrapper>
      <Wrapper>
        <Main noOfItem={noOfItem}>
          <ColumnWrapper>
            {!isLoading &&
              filters.parameters.map((parameter, i) => {
                return (
                  <>
                    <FilterParameter
                      key={parameter.id}
                      parameter={parameter}
                      indexNo={i}
                    />

                    {i === filters.parameters.length - 1 && (
                      <Container noOfItem={noOfItem}>
                        <LinkWrapper
                          tooltip={
                            <Tooltip fixed_position>
                              {t("filterPage.Tooltip")}
                            </Tooltip>
                          }
                        >
                          <PlusButton handleAdd={handleAdd}>add</PlusButton>
                        </LinkWrapper>

                        {isDeleteDialogOpen ? (
                          <FilterDialog
                            key={filters.parameters.id}
                            initialData={{ name: "", tag: "" }}
                            parameter={filters.parameters}
                            closeDialog={() => setDeleteDialogOpen(false)}
                          />
                        ) : null}
                      </Container>
                    )}
                  </>
                );
              })}
          </ColumnWrapper>
        </Main>
        <Subtitle>{t("filterPage.Subtitle")}</Subtitle>
      </Wrapper>
      <ButtonWrapper>
        <SaveButton disabled={!changes} />
        {/* <HistoryButton /> */}
      </ButtonWrapper>
    </Wrapper>
  ) : (
    <Loader />
  );
}
const LinkWrapper = styled(Anchor)`
  &.dnb-anchor:hover {
    box-shadow: none;
    color: none;
  }
  &.dnb-anchor .dnb-icon {
    display: inline-block;
    transform: translateY(0em);
  }
`;
const Main = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  margin-left: ${(props) => (props.noOfItem ? "23%" : "18%")};
  margin-right: ${(props) => (props.noOfItem ? "23%" : "18%")};
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  @media screen and (max-height: 1000px) {
    margin-top: var(--spacing-large);
  }
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${(props) => (props.noOfItem ? "1rem" : "1.5rem")};
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  border-radius: 8px;
  flex-wrap: wrap;
  row-gap: 60px;
  flex-direction: row;
  justify-content: center;
`;

const Subtitle = styled.div`
  font-size: var(--font-size-x-small);
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;
